import React, { createContext, useState, useEffect } from 'react';

// Create a context for the UTM parameters
export const UtmContext = createContext();

export const UtmProvider = ({ children }) => {
  const [utmParams, setUtmParams] = useState('');

  useEffect(() => {
    // Store the UTM parameters in the state when the component mounts
    setUtmParams(window.location.search);
  }, []);

  return (
    <UtmContext.Provider value={utmParams}>
      {children}
    </UtmContext.Provider>
  );
};