import { useEffect } from 'react';
import '../index.css';
import packageJson from '../package.json'
import { UtmProvider } from '../contexts/UtmContext';


function MyApp({ Component, pageProps }) {
	useEffect(() => {
		console.log(
			`\n%c \u{00A9} newform v${packageJson.version} \n\n`,
			"color: #fff; background: #0047FF; padding:5px 0;"
		)
	}, [])

	return (
	<UtmProvider>
		<Component {...pageProps} />
	</UtmProvider>
	);
}


export default MyApp;
